#root {
  align-items: center;
}

body, html {
}

@font-face {
  font-family: 'NunitoBold';
  src: url("./fonts/Nunito-Bold.ttf") format('truetype');
}

@font-face {
  font-family: 'NunitoExtrabold';
  src: url("./fonts/Nunito-ExtraBold.ttf") format('truetype');
}

@font-face {
  font-family: 'NunitoRegular';
  src: url("./fonts/Nunito-Regular.ttf") format('truetype');
}

@font-face {
  font-family: 'NunitoSemibold';
  src: url("./fonts/Nunito-SemiBold.ttf") format('truetype');
}

